import styles from './Sidepanel.module.scss'; // TODO: use own module.scss
import attendeeStyles from './AttendeesList.module.scss';

import React from 'react';
import { IAttendee } from '../../store/business/interfaces';
import { Persona, PersonaSize } from 'office-ui-fabric-react';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import { formatFloat } from '../../shared/utils';

export interface Props {
	votableAttendees?: IAttendee[];
	showNobodyLabel: boolean;
	showVoteWeight: boolean;
	title: string;
	onClose?: () => void;
}

export const AttendeesList = (props: Props) => {
	const survey = useSelector((state: AppState) => state.business.survey);

	if (!props.votableAttendees) {
		return <></>;
	}

	const getVoteWeightText = (attendee: IAttendee): string => {
		if (!props.showVoteWeight) return '';

		if (!survey.useVoteWeightInPercent && attendee.voteWeight === 1) return '';

		return `mit ${formatFloat(attendee.voteWeight)}${survey.useVoteWeightInPercent ? '%' : ' Stimmen'}`;
	};

	return (
		<div>
			{props.votableAttendees?.length > 0 && (
				<>
					<h4 className={attendeeStyles.attendeesListTitle}>{props.title}</h4>
					<ul className={[styles.memberList, attendeeStyles.attendeesGrid].join(' ')}>
						{props.showNobodyLabel && props.votableAttendees.length === 0 && (
							<li className={styles.memberItem} key='0'>
								<span>Niemand</span>
							</li>
						)}
						{props.votableAttendees?.length > 0 && (
							<>
								{props.votableAttendees.map((m) => {
									return (
										<li className={styles.memberItem} key={m.id}>
											<Persona
												text={m.title}
												showSecondaryText={props.showVoteWeight && (m.voteWeight > 1 || survey.useVoteWeightInPercent)}
												secondaryText={getVoteWeightText(m)}
												size={PersonaSize.size48}
											/>
										</li>
									);
								})}
							</>
						)}
					</ul>
				</>
			)}
		</div>
	);
};
