import round from 'lodash/round';
import floor from 'lodash/floor';
import { ITextFieldProps, TextField } from 'office-ui-fabric-react';
import React, { useEffect, useState } from 'react';

export interface Props extends Omit<ITextFieldProps, 'value' | 'onChange' | 'type' | 'min' | 'step'> {
	precision: number;
	value: number;
	onChange: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: number) => void;
}

const calcTextValue = (value: number | undefined, precision: number | undefined) => {
	return `${round(value ?? 0, precision ?? 2)}`;
};

export const NumberTextField = (props: Props) => {
	const [textValue, setTextValue] = useState(calcTextValue(props.value, props.precision));
	const [step, setStep] = useState(Math.pow(10, -1 * props.precision));

	useEffect(() => {
		const newStep = Math.pow(10, -1 * props.precision);
		setStep(newStep);
	}, [props.precision]);

	useEffect(() => {
		setTextValue(calcTextValue(props.value, props.precision));
	}, [props.value, props.precision]);

	return (
		<TextField
			{...props}
			defaultValue={calcTextValue(props.value, props.precision)}
			value={textValue}
			type='number'
			min={step}
			step={step}
			onChange={(e, v) => {
				setTextValue(v);
			}}
			onBlur={(e) => {
				const newValue = floor(parseFloat(textValue), props.precision) || 0;
				if (newValue !== props.value) {
					props.onChange(e, newValue);
				}
			}}
		/>
	);
};
