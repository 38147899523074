import { IBusinessState } from './interfaces';
import {
	IBusinessActionTypes,
	DATA_LOADING,
	SURVEY_LOADED,
	QUESTIONS_LOADED,
	UPDATE_QUESTION,
	REMOVE_QUESTION,
	MEMBERS_LOADED,
	UPSERT_MEMBERS,
	REMOVE_MEMBER,
	STARTSTOP_QUESTION,
	RESET_ALL,
	SURVEYS_LOADED,
	SET_DESIGN
} from './types';

const initialState: IBusinessState = {
	surveys: [],
	design: null,
	loading: {
		survey: false,
		surveys: false,
		questions: false,
		question: false,
		members: false,
	},
	
};

function innerReducer(state: IBusinessState, action: IBusinessActionTypes): IBusinessState {
	switch (action.type) {
		case RESET_ALL: {
			return initialState;
		}

		case DATA_LOADING: {
			return { ...state, loading: { ...state.loading, [action.businessDataType]: action.isLoading } };
		}

		case SURVEYS_LOADED: {
			return { ...state, surveys: action.surveys };
		}

		case SURVEY_LOADED: {
			return { ...state, survey: action.survey };
		}

		case QUESTIONS_LOADED: {
			return {
				...state,
				questions: action.questions.sort((a, b) => (a.sortOrder || '').localeCompare(b.sortOrder || '')),
			};
		}

		case UPDATE_QUESTION: {
			return {
				...state,
				questions: state.questions
					.filter((q) => q.id !== action.question.id)
					.concat([action.question])
					.sort((a, b) => (a.sortOrder || '').localeCompare(b.sortOrder || '')),
			};
		}

		case REMOVE_QUESTION: {
			return {
				...state,
				questions: state.questions.filter((q) => q.id !== action.question.id),
			};
		}

		case MEMBERS_LOADED: {
			return {
				...state,
				membersSurveyId: action.surveyId,
				members: {
					entities: action.members,
					lastChanged: action.lastChanged ? new Date(action.lastChanged) : undefined,
				},
			};
		}

		case UPSERT_MEMBERS: {
			let upsertedMembers = state.members.entities;
			action.members.forEach(member => {
				const upsertedMemberIndex = state.members.entities.findIndex(m => m.id === member.id);
				if(upsertedMemberIndex > -1) {
					upsertedMembers[upsertedMemberIndex] = member;
				}
				else {
					upsertedMembers.splice(0, 0, member);
				}
			});
			return {
				...state,
				membersSurveyId: action.surveyId,
				members : {
					entities: upsertedMembers,
					lastChanged: action.lastChanged ? new Date(action.lastChanged) : undefined,
				}
			};
		}

		case REMOVE_MEMBER: {
			return {
				...state,
				membersSurveyId: action.surveyId,
				members: {
					entities: state.members.entities.filter((m) => m.id !== action.member.id),
					lastChanged: action.lastChanged ? new Date(action.lastChanged) : undefined,
				}
			};
		}

		case STARTSTOP_QUESTION: {
			return {
				...state,
				questions: state.questions
					.filter((q) => q.id !== action.question.id)
					.concat([action.question])
					.sort((a, b) => (a.sortOrder || '').localeCompare(b.sortOrder || '')),
			};
		}

		case SET_DESIGN: {
			return {
				...state,
				design: action.design
			};
		}

	}
	return state;
}

function businessReducer(state = initialState, action: IBusinessActionTypes): IBusinessState {
	const result = innerReducer(state, action);
	if (process.env.NODE_ENV !== 'production') console.log(`businessReducer`, action, state, result);
	return result;
}

export default businessReducer;
