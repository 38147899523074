import styles from './Sidepanel.module.scss'; // TODO: use own module.scss
import React, { useState } from 'react';
import { ISurveyCreationInfo } from '../../store/business/interfaces';
import {
	TextField,
	PrimaryButton,
	DefaultButton,
	CommandButton,
	Toggle,
	DatePicker,
	DayOfWeek,
} from 'office-ui-fabric-react';
import { DayPickerStrings } from '../../i18n/datepicker.de';
import format from 'date-fns/format';
import { IconWithCallout } from '../atoms/IconWithCallout';

export interface Props {
	onSave: (survey: ISurveyCreationInfo) => void;
	onCancel: () => void;
}

export const SurveyCreationForm = (props: Props) => {
	const [title, setTitle] = useState('');
	const [date, setDate] = useState(new Date());
	const [copyMembers, setCopyMembers] = useState(false);
	const [copyQuestions, setCopyQuestions] = useState(false);
	const [useQuickVotes, setUseQuickVotes] = useState(false);
	const [errorTitle, setErrorTitle] = useState('');

	const onClickSave = () => {
		if (title) {
			props.onSave({
				title,
				date,
				disableResultsInMemberView: false,
				copyMembersFromCurrentSurvey: copyMembers,
				copyQuestionsFromCurrentSurvey: copyQuestions,
				useQuickVotes: useQuickVotes
			});
		} else {
			setErrorTitle('Bitte geben Sie einen Namen ein.');
		}
	};

	const onFormatDate = (dateToFormat: Date): string => {
		return format(dateToFormat, 'dd.MM.yyyy');
	};

	return (
		<div className={styles.formContainer}>
			<header>
				<div className={[styles.flexContainer, styles.spaceBetween].join(' ')}>
					<div className={styles.panelHeader}>
						<h1>Neue Sitzung</h1>
					</div>
					<div className={styles.panelButtons}>
						<CommandButton
							onClick={() => {
								props.onCancel();
							}}
							iconProps={{ iconName: 'ChromeClose' }}
							className={styles.closeIcon}
						/>
					</div>
				</div>
			</header>
			<TextField
				title='Name der Sitzung'
				maxLength={40}
				onChange={(evt, val) => {
					setErrorTitle('');
					setTitle(val);
				}}
				placeholder='Name der Sitzung eingeben'
				onFocus={(evt) => {
					evt.currentTarget.select();
				}}
				errorMessage={errorTitle}
			/>
			<DatePicker
				strings={DayPickerStrings}
				firstDayOfWeek={DayOfWeek.Monday}
				showGoToToday={false}
				label='Datum'
				placeholder='Datum der Sitzung wählen'
				onSelectDate={(val) => setDate(val)}
				value={date}
				formatDate={onFormatDate}
			/>
			<div className={styles.formRow}>
				<Toggle
					disabled={copyMembers}
					label='Schnelle Abstimmungen'
					onChanged={(val) => {
						if(val) setCopyMembers(false);
						setUseQuickVotes(val);
					}}
					inlineLabel
					checked={useQuickVotes}
				/>
				<IconWithCallout
					iconName='Info'
					renderCalloutContent={()=>{ return (
						<span>Teilnehmer können sich ohne PIN anmelden. Die Teilnehmerliste wird nach der Anmeldung automatisch befüllt. Bei einer aktivierten Stimmengewichtung sind keine schnellen Abstimmungen möglich.</span>
					);}}
					iconContainerClassName="votrInfoIcon"
				/>
			</div>
			<span className={styles.label}>Aus aktueller Sitzung übernehmen</span>
			<div className={styles.formRow}>
				<Toggle
					label='Abstimmungen'
					onChanged={(val) => {
						setCopyQuestions(val);
					}}
					inlineLabel
					checked={copyQuestions}
				/>
			</div>
			<div className={styles.formRow}>
				<Toggle
					disabled={useQuickVotes}
					label='Teilnehmer'
					onChanged={(val) => {
						if(val) setUseQuickVotes(false);
						setCopyMembers(val);
					}}
					inlineLabel
					checked={copyMembers}
				/>
			</div>
			<div className={styles.buttonContainer}>
			<DefaultButton text='Abbrechen' onClick={() => props.onCancel()} />
				<PrimaryButton text='Speichern' onClick={onClickSave} />
			</div>
		</div>
	);
};
