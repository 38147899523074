import React, { useState } from 'react';
import { MembersForm } from '../molecules/MembersForm';
import { IMembers, ISurvey } from '../../store/business/interfaces';
import { MemberService } from '../../services/MemberService';
import { useSelector } from 'react-redux';
import { AppState, store } from '../../store';
import { SurveyService } from '../../services/SurveyService';
import { Panel, PanelType } from 'office-ui-fabric-react';
import { MemberImport } from '../molecules/MemberImport';


export interface Props {
	members: IMembers;
	survey: ISurvey;
	readOnly?: boolean;
	onClose: () => void;
}

export const Members = (props: Props) => {
	const memberUrl = useSelector((state: AppState) => state.system.memberBasePath);
	const memberTenantPath = useSelector((state: AppState) => state.system.memberTenantPath);
	const { business } = store.getState();

	const [showImportPanel, setShowImportPanel] = useState(false);

	return (
		<div>
			<MembersForm
				readOnly={props.readOnly}
				members={props.members}
				survey={props.survey}
				onUpsertSurvey={(survey) => {
					SurveyService.updateSurvey(survey);
				}}
				onUpsertMembers={(members) => {
					MemberService.upsert(props.survey, members);
				}}
				onDeleteMember={(member) => {
					MemberService.delete(props.survey, member);
				}}
				onDeleteAllMembers={() => {
					MemberService.deleteAll(props.survey);
				}}
				onExportMembers={() => {
					MemberService.export(props.survey, `${memberUrl}${memberTenantPath?`/${memberTenantPath}`:''}`);
				}}
				onExportMembersProtocol={() => {
					MemberService.exportProtocol(props.survey);
				}}
				onCancel={() => {
					props.onClose();
				}}
				onMembersImport={() => {
					setShowImportPanel(true);
				}}
			/>
			<Panel
				isLightDismiss={true}
				isOpen={showImportPanel}
				type={PanelType.custom}
				customWidth='420px'
				layerProps={{ eventBubblingEnabled: true }}
				onDismiss={() => {
					setShowImportPanel(false);
				}}
			>
				<MemberImport
					onUpload={async (file) => { 
						const importResponse = await MemberService.import(props.survey, file);
						await SurveyService.loadSurvey(business.survey.id, true);
						return importResponse;
					}}
					onClose={() => setShowImportPanel(false)}
					survey={props.survey}
				/>
			</Panel>
		</div>
	);
};
