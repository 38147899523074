import React from 'react';
import { FontIcon } from 'office-ui-fabric-react';
import styles from './ToggleableIcon.module.scss';

export interface IToggleableIcon {
	toggled: boolean;
	iconName: string;
	text: string;
}

export interface Props {
	icons: IToggleableIcon[];
}

export const ToggleableIcon = (props: Props) => {
	let toggledIcon = props?.icons?.find(i => i.toggled);

	return (
		<>
			{toggledIcon && (
				<span className={styles.toggleableIcon}>
					<FontIcon iconName={toggledIcon.iconName} />
					<span>{toggledIcon.text}</span>
				</span>
			)}
		</>
	);
};
