import React, { useState } from 'react';
import { TextQuestionForm } from '../organisms/QuestionForms/TextQuestionForm';
import { ChoiceQuestionForm } from '../organisms/QuestionForms/ChoiceQuestionForm';
import { PersonQuestionForm } from '../organisms/QuestionForms/PersonQuestionForm';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import { QuestionService } from '../../services/QuestionService';
import { IQuestion } from 'src/store/business/interfaces';

export interface Props {
	questionId: string;
	onClose: (wasCanceled: boolean) => void;
	onNotFound?: () => void;
}

export const QuestionEditor = (props: Props) => {
	const survey = useSelector((state: AppState) => state.business.survey);
	const questions = useSelector((state: AppState) => state.business.questions);
	const [question, setQuestion] = useState<IQuestion>(questions.find((q) => q.id === props.questionId));

	if (!question || !survey) {
		props.onNotFound?.();
		return <></>;
	}

	return (
		<>
			{question.kind === 'choice' && (
				<ChoiceQuestionForm
					question={question}
					disabled={question.status !== 'unsaved' && question.status !== 'new'}
					setQuestion={setQuestion}
					onCancel={() => {
						props.onClose(true);
					}}
					onSave={(question) => {
						QuestionService.upsertQuestion(survey, question).then(() => {
							props.onClose(false);
						});
					}}
				/>
			)}
			{question.kind === 'person' && (
				<PersonQuestionForm
					question={question}
					disabled={question.status !== 'unsaved' && question.status !== 'new'}
					setQuestion={setQuestion}
					onCancel={() => {
						props.onClose(true);
					}}
					onSave={(question) => {
						QuestionService.upsertQuestion(survey, question).then(() => {
							props.onClose(false);
						});
					}}
				/>
			)}
			{question.kind === 'text' && (
				<TextQuestionForm
					question={question}
					disabled={question.status !== 'unsaved' && question.status !== 'new'}
					setQuestion={setQuestion}
					onCancel={() => {
						props.onClose(true);
					}}
					onSave={(question) => {
						QuestionService.upsertQuestion(survey, question).then(() => {
							props.onClose(false);
						});
					}}
				/>
			)}
		</>
	);
};
