import React, { ReactNode } from 'react';
import styles from './LoadingOverlay.module.scss';
import { PulseLoader } from 'react-spinners';
import { useLoading } from '../../shared/hooks';
import { BusinessDataType } from '../../store/business/enums';
import { SystemLoading } from '../../store/system/enums';
import { Modal } from 'office-ui-fabric-react';

export interface Props {
	children?: ReactNode;
	excludeBusiness?: BusinessDataType[];
	excludeSystem?: SystemLoading[];
}
export const LoadingOverlay = (props: Props) => {
	const loading = useLoading(props.excludeSystem, props.excludeBusiness);

	return (
		<div>
			{props.children}
			<Modal isOpen={loading} isBlocking={true}>
				<div className={styles.loading}>
					<div className={styles.frame}>
						<PulseLoader size={15} margin={5} color={'#0f73ba'} />
						<span className={styles.text}>Bitte warten</span>
					</div>
				</div>
			</Modal>
		</div>
	);
};
