import React from "react";
import {QuestionFormToggle} from "./QuestionFormToggle";

export interface Props {
	checked: boolean;
	disabled: boolean;
	onChanged: (checked:boolean)=>void;
}

export const SecurityToggle = (props: Props) => {

	return (
		<QuestionFormToggle
			checkedLabelText="geheim"
			checkedLabelIconName="Lock"
			uncheckedLabelText="offen"
			uncheckedLabelIconName="Unlock"
			checked={props.checked}
			disabled={props.disabled}
			onChanged={props.onChanged}
		/>
	);
};