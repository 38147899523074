import React, { useContext, useEffect, useCallback, useState } from 'react';
import styles from './Hub.module.scss';
import { SurveyControl } from '../molecules/SurveyControl';
import { QuestionEditor } from './QuestionEditor';
import { Members } from './Members';
import { Share } from './Share';
import { Result } from './Result';
import { QuestionOverview } from '../molecules/QuestionOverview';
import { SurveyService } from '../../services/SurveyService';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import { QuestionService } from '../../services/QuestionService';
import { Switch, Route, useHistory } from 'react-router-dom';
import { MemberService } from '../../services/MemberService';
import { SignalRContext, ISignalRConnection } from '../../shared/SignalRContext';
import { Panel, PanelType } from 'office-ui-fabric-react';
import { ISurvey } from '../../store/business/interfaces';
import throttle from 'lodash/throttle';
import { EmptySurveyPanel } from '../molecules/EmptySurveyPanel';
import { FeedbackButton } from '../molecules/FeedbackButton';
import { SurveyEditor } from './SurveyEditor';
import { Logo } from '../../shared/components/atoms/Logo';
import { Settings } from './Settings';
import { ConfirmationDialog } from '../atoms/ConfirmationDialog';


export interface HubProps {}
/**
 * used to create Surveys, Questions and add Members to a Survey
 * @returns SurveyControl, ConfirmationDialog, EmptySurveyPanel, QuestionOverview 
 * and Routes to Panel => Share, Settings, Members...
 * 
 */
export const Hub = () => {
	const connection = useContext<ISignalRConnection>(SignalRContext);
	const survey = useSelector((state: AppState) => state.business.survey);
	const surveys = useSelector((state: AppState) => state.business.surveys);
	const questions = useSelector((state: AppState) => state.business.questions);
	const members = useSelector((state: AppState) => state.business.members);
	const history = useHistory();
	const [resultPanelWidth, setResultPanelWidth] = useState<string>('66vw');

	const [hideDeleteSurveyDialog, setHideDeleteSurveyDialog] = useState(true);
	const [hideArchiveSurveyDialog, setHideArchiveSurveyDialog] = useState(true);

	const loadQuestion = useCallback(
		throttle((currentSurvey: ISurvey, questionId: string) => {
			QuestionService.loadQuestion(currentSurvey, questionId);
		}, 5000),
		[]
	);

	useEffect(() => {
		connection.on('admin', (args) => {
			if(args.type === 'member') {
				if(args.action === 'upsert') {
					if(survey) MemberService.load(survey, args.memberId);
				}
			}
			else if(args.type === 'question') {
				if(args.action === 'vote') {
					if (survey) loadQuestion(survey, args.questionId);
				}
			}
		});
		return () => {
			connection.off('admin');
		};
	}, [survey, connection, loadQuestion]);

	const cancelCreateEditItem = (props: any) => {
		const currentQuestion = questions.find((q) => q.id === props.match.params.questionId);
		if (currentQuestion && currentQuestion.status === 'unsaved') {
			QuestionService.removeQuestion(currentQuestion);
		}
	};

	const createQuestion = () => {
		const question = QuestionService.createQuestion();
		history.push(`/${survey.id}/edit/${question.id}`);
	};

	const manageMembers = () => {
		history.push(`/${survey.id}/members`);
	};

	const giveFeedback = () => {
		window.open('https://rebrand.ly/votr-feedback');
	};

	const toggleResultWidth = useCallback(() => {
		setResultPanelWidth((v) => {
			return v === '66vw' ? '100vw' : '66vw';
		});
	}, []);

	if (!survey) {
		return <></>;
	}

	return (
		<div className={styles.hubContainer}>
			<div className={styles.hubFlexContainer}>
				<SurveyControl
					surveys={surveys}
					survey={survey}
					questions={questions}
					members={members ? members.entities : []}
					onCreateQuestion={() => {
						createQuestion();
					}}
					onManageMembers={() => {
						manageMembers();
					}}
					onExportSurvey={() => {
						SurveyService.exportSurvey(survey);
					}}
					onShareSurvey={() => {
						history.push(`/${survey.id}/share`);
					}}
					onEditSurvey={() => {
						history.push(`/${survey.id}/edit`);
					}}
					onSelectSurvey={(s) => {
						history.push(`/${s.id}`);
					}}
					onNewSurvey={() => {
						history.push(`/create`);
					}}
					onUpdateSurvey={(updatedSurvey) => {
						SurveyService.updateSurvey(updatedSurvey);
					}}
					onArchiveSurvey={() => {
						setHideArchiveSurveyDialog(false);
					}}
					onDeleteSurvey={() => {
						setHideDeleteSurveyDialog(false);
					}}
				/>
				<ConfirmationDialog
					hidden={hideArchiveSurveyDialog}
					title={'Sitzung archivieren'}
					message={survey.title ? `Wollen Sie die Sitzung <b>${survey.title}</b> wirklich archivieren?` : `Wollen Sie diese Sitzung wirklich archivieren?`}
					onConfirm={()=>{
						setHideArchiveSurveyDialog(true);
						SurveyService.archiveSurvey(survey);
					}}
					onDismiss={()=>{
						setHideArchiveSurveyDialog(true);
					}}
				/>
				<ConfirmationDialog
					hidden={hideDeleteSurveyDialog}
					title={'Sitzung löschen'}
					message={survey.title ? `Wollen Sie die Sitzung <b>${survey.title}</b> wirklich löschen?` : `Wollen Sie diese Sitzung wirklich löschen?`}
					onConfirm={()=>{
						setHideDeleteSurveyDialog(true);
						SurveyService.deleteSurvey(survey).then(() => {
							history.push(`00000000-0000-0000-0000-000000000000`);
						});
					}}
					onDismiss={()=>{
						setHideDeleteSurveyDialog(true);
					}}
				/>
				<EmptySurveyPanel
					createQuestionDisabled={survey.readOnly}
					onCreateQuestion={() => {
						createQuestion();
					}}
					onGiveFeedback={() => {
						giveFeedback();
					}}
					onManageMembers={() => {
						manageMembers();
					}}
					visible={
						questions != null && (questions.length === 0 || (questions.length === 1 && questions[0].status === 'unsaved'))
					}
				/>
				<QuestionOverview
					survey={survey}
					questions={questions}
					onSelect={(q) => {
						setResultPanelWidth('66vw');
						history.push(`/${survey.id}/display/${q.id}`);
					}}
					onExport={(q) => QuestionService.exportQuestion(survey, q)}
					onEdit={(q) => {
						history.push(`/${survey.id}/edit/${q.id}`);
					}}
					onReset={(q) => QuestionService.resetQuestion(survey, q)}
					onDelete={(q) => QuestionService.deleteQuestion(survey, q)}
					onToggleQuestion={(q, start) => {
						SurveyService.startStopQuestion(survey, q);
					}}
				/>
				<div className={styles.footer}>
					<Logo />
					<FeedbackButton onClick={() => giveFeedback()} />
				</div>
			</div>
			<Switch>
				<Route
					path='/:surveyId/display/:questionId'
					exact={true}
					render={(props) => (
						<Panel
							isLightDismiss={true}
							isOpen={true}
							onDismiss={() => {
								history.push(`/${survey.id}`);
							}}
							type={PanelType.custom}
							customWidth={resultPanelWidth}
							layerProps={{ eventBubblingEnabled: true }}
						>
							<Result
								survey={survey}
								key={props.match.params.questionId}
								questionId={props.match.params.questionId}
								onClose={() => {
									history.push(`/${survey.id}`);
								}}
								onToggleQuestion={(q, start) => {
									SurveyService.startStopQuestion(survey, q);
								}}
								onExport={(q) => QuestionService.exportQuestion(survey, q)}
								onEdit={(q) => {
									history.push(`/${survey.id}/edit/${q.id}`);
								}}
								onReset={(q) => QuestionService.resetQuestion(survey, q)}
								onDelete={(q) => {
									QuestionService.deleteQuestion(survey, q);
									history.push(`/`);
								}}
								onTogglePanelSize={() => {
									toggleResultWidth();
								}}
							/>
						</Panel>
					)}
				></Route>
				<Route
					path='/:surveyId/members'
					exact={true}
					render={(props) => (
						<Panel
							isLightDismiss={true}
							isOpen={true}
							onDismiss={() => {
								MemberService.sort(survey);
								history.push(`/${survey.id}`);
							}}
							type={PanelType.custom}
							customWidth='100vw'
							layerProps={{ eventBubblingEnabled: true }}
						>
							<Members
								readOnly={survey.readOnly}
								members={members}
								survey={survey}
								onClose={() => {
									MemberService.sort(survey);
									history.push(`/${survey.id}`);
								}}
							/>
						</Panel>
					)}
				></Route>
				<Route
					path='/:surveyId/share'
					exact={true}
					render={(props) => (
						<Panel
							isLightDismiss={true}
							isOpen={true}
							onDismiss={() => history.push(`/${survey.id}`)}
							type={PanelType.custom}
							customWidth='420px'
							layerProps={{ eventBubblingEnabled: true }}
						>
							<Share
								surveyCode={survey.code}
								surveyId={survey.id}
								onClose={() => history.push(`/${survey.id}`)}
							></Share>
						</Panel>
					)}
				></Route>
				<Route
					path='/:surveyId/edit'
					exact={true}
					render={(props) => (
						<Panel
							isLightDismiss={true}
							isOpen={true}
							onDismiss={() => history.push(`/${survey.id}`)}
							type={PanelType.custom}
							customWidth='420px'
							layerProps={{ eventBubblingEnabled: true }}
						>
							<Settings
								onClose={() => history.push(`/${survey.id}`)}
								survey={survey}
								members={members}
								readOnly={survey.readOnly}
								onUpsertSurvey={(upsertedSurvey) => SurveyService.updateSurvey(upsertedSurvey)}
							></Settings>
						</Panel>
					)}
				></Route>
				<Route
					path='/:surveyId/edit/:questionId'
					exact={true}
					render={(props) => (
						<Panel
							isLightDismiss={true}
							isOpen={true}
							onDismiss={() => {
								cancelCreateEditItem(props);
								history.goBack();
							}}
							type={PanelType.custom}
							customWidth='50vw'
							layerProps={{ eventBubblingEnabled: true }}
						>
							<QuestionEditor
								key={props.match.params.questionId}
								questionId={props.match.params.questionId}
								onClose={(wasCanceled) => {
									if (wasCanceled) {
										cancelCreateEditItem(props);
									}
									history.goBack();
								}}
								onNotFound={() => {
									history.push(`/${survey.id}`);
								}}
							/>
						</Panel>
					)}
				></Route>
				<Route
					path='/create'
					exact={true}
					render={(props) => (
						<Panel
							isLightDismiss={true}
							isOpen={true}
							onDismiss={() => {
								history.goBack();
							}}
							type={PanelType.custom}
							customWidth='50vw'
							layerProps={{ eventBubblingEnabled: true }}
						>
							<SurveyEditor
								onClose={(surveyId) => {
									if (surveyId) {
										history.push(`/${surveyId}`);
									} else {
										history.goBack();
									}
								}}
							/>
						</Panel>
					)}
				></Route>
				<Route path='/'></Route>
			</Switch>
		</div>
	);
};
