import {
	IBusinessActionTypes,
	DATA_LOADING,
	SURVEY_LOADED,
	QUESTIONS_LOADED,
	UPDATE_QUESTION,
	REMOVE_QUESTION,
	MEMBERS_LOADED,
	UPSERT_MEMBERS,
	REMOVE_MEMBER,
	STARTSTOP_QUESTION,
	RESET_ALL,
	SURVEYS_LOADED,
	SET_DESIGN
} from './types';

import { BusinessDataType } from './enums';
import { ISurvey, IQuestion, IMember, ISurveyBase, IDesign } from './interfaces';

export const resetAll = (): IBusinessActionTypes => {
	return {
		type: RESET_ALL,
	};
};

export const businessLoading = (businessDataType: BusinessDataType, isLoading: boolean): IBusinessActionTypes => {
	return {
		type: DATA_LOADING,
		businessDataType,
		isLoading,
	};
};

export const surveysLoaded = (surveys: ISurveyBase[]): IBusinessActionTypes => {
	return {
		type: SURVEYS_LOADED,
		surveys,
	};
};

export const surveyLoaded = (survey: ISurvey): IBusinessActionTypes => {
	return {
		type: SURVEY_LOADED,
		survey,
	};
};

export const questionsLoaded = (questions: IQuestion[]): IBusinessActionTypes => {
	return {
		type: QUESTIONS_LOADED,
		questions,
	};
};

export const updateQuestion = (question: IQuestion): IBusinessActionTypes => {
	return {
		type: UPDATE_QUESTION,
		question,
	};
};

export const removeQuestion = (question: IQuestion): IBusinessActionTypes => {
	return {
		type: REMOVE_QUESTION,
		question,
	};
};

export const membersLoaded = (
	surveyId: string,
	members: IMember[],
	lastChanged: Date | undefined
): IBusinessActionTypes => {
	return {
		type: MEMBERS_LOADED,
		surveyId,
		members,
		lastChanged,
	};
};

export const upsertMembers = (surveyId: string, members: IMember[], lastChanged: Date | undefined): IBusinessActionTypes => {
	return {
		type: UPSERT_MEMBERS,
		surveyId,
		members,
		lastChanged
	};
};

export const removeMember = (surveyId: string, member: IMember, lastChanged: Date | undefined): IBusinessActionTypes => {
	return {
		type: REMOVE_MEMBER,
		surveyId,
		member,
		lastChanged
	};
};

export const startStopQuestion = (question: IQuestion): IBusinessActionTypes => {
	return {
		type: STARTSTOP_QUESTION,
		question,
	};
};

export const setDesign = (design : IDesign) : IBusinessActionTypes => {
	return {
		type: SET_DESIGN,
		design
	};
};