import React, { useState, useEffect } from 'react';
import styles from './Sidepanel.module.scss';
import { CommandButton, Panel, PanelType, Persona, PersonaSize } from 'office-ui-fabric-react';
import { IQuestion, IAnswerOption } from '../../store/business/interfaces';
import { useTranslation } from 'react-i18next';
import { formatFloat } from '../../shared/utils';
import { renderCollapsedSvg, renderExpandedSvg } from '../atoms/svg';
import { useSelector } from 'react-redux';
import { AppState } from 'src/store';

export interface Props {
	answerOption: IAnswerOption;
	question: IQuestion;
	onDismiss: () => void;
}

const renderVoteValueAttendance = (question: IQuestion, answerOption: IAnswerOption, voteValue: string, usePercent: boolean) => {
	const attendeesForVoteValue = answerOption.attendees.filter((a) => a.voteValue === voteValue);
	const attendeeIdsForVoteValue = attendeesForVoteValue.map((a) => a.id);
	const attendees = question.attendees.filter((a) => attendeeIdsForVoteValue.includes(a.id));
	return attendees.map((a) => {
		const voteWeight = attendeesForVoteValue.find((a4vv) => a4vv.id === a.id)?.voteWeight ?? 1;
		
		let voteText = undefined;
		if(usePercent){
			voteText = `mit ${formatFloat(voteWeight)}%`;
		}
		else if(voteWeight > 1){
			voteText = `mit ${formatFloat(voteWeight)} Stimmen`;
		}
		
		return (
			<li key={`a4vv-${a.id}-${voteValue}`} className={styles.memberItem}>
				<Persona
					text={a.text}
					secondaryText={voteText}
					size={PersonaSize.size48}
				/>
			</li>
		);
	});
};

export const ResultPersonDetailsPanel = (props: Props) => {
	const survey = useSelector((state: AppState) => state.business.survey);
	
	const [personDetails, setPersonDetails] = useState<IAnswerOption | undefined>();
	const [personDetailsExpanded, setPersonDetailsExpanded] = useState<boolean>(false);

	const { t } = useTranslation();

	useEffect(() => {
		setPersonDetails(props.answerOption);
		setPersonDetailsExpanded(false);
	}, [props.answerOption]);

	return (
		<Panel
			isOpen={!!personDetails}
			customWidth={personDetailsExpanded ? '100vw' : '66vw'}
			type={PanelType.custom}
			onDismiss={() => {
				props.onDismiss();
			}}
		>
			{personDetails && (
				<div className={styles.formContainer}>
					<header>
						<div className={[styles.flexContainer, styles.spaceBetween].join(' ')}>
							<div className={styles.panelHeader}>
								<h1>Beteiligung</h1>
							</div>
							<div className={[styles.flexContainer, styles.panelButtons].join(' ')}>
								<CommandButton
									onClick={() => {
										setPersonDetailsExpanded((v) => !v);
									}}
								>
									{!personDetailsExpanded ? renderExpandedSvg() : renderCollapsedSvg()}
								</CommandButton>
								<CommandButton
									onClick={() => {
										props.onDismiss();
									}}
									iconProps={{ iconName: 'ChromeClose' }}
									className={styles.closeIcon}
								/>
							</div>
						</div>
						<div className={styles.meta}>
							<div className={[styles.topLeft, styles.personDetailsTitle].join(' ')}>{personDetails.title}</div>
						</div>
					</header>
					<main className={styles.grid3}>
						{['yes', 'no', 'abstent'].map((voteValue) => {
							return (
								<ul key={`voteValue-${voteValue}`} className={styles.memberList}>
									<li className={styles.personDetailsVoteValue}>{t(`voting.person.${voteValue}`)}</li>
									{renderVoteValueAttendance(props.question, personDetails, voteValue, survey.useVoteWeightInPercent)}
								</ul>
							);
						})}
					</main>
				</div>
			)}
		</Panel>
	);
};
