import React, { useState, ReactNode, useEffect } from 'react';
import {
	HubConnection,
	HubConnectionBuilder,
	LogLevel,
	DefaultHttpClient,
	HttpRequest,
	HttpResponse,
	ILogger,
	IRetryPolicy,
	RetryContext,
} from '@microsoft/signalr';

interface IProps {
	children?: ReactNode;
	url: string;
	userId: string;
}

export interface ISignalRConnection {
	on(targetName: string, callback: (...args: SignalRArgs[]) => void): void;
	off(targetName: string): void;
	onReconnected(callback: () => void): void;
	onClose(callback: () => void): void;
	invoke(targetName: string, ...args: SignalRArgs[]): Promise<any>;
}

type SignalRArgs = 
	| SignalRQuestionArgs
	| SignalRSurveyArgs
	| SignalRMemberArgs
	| SignalRConnectionArgs
;

export interface SignalRQuestionArgs {
	type: 'question';
	questionId: string;
	action: 'upsert' | 'delete' | 'start' | 'stop' | 'vote';
}

export interface SignalRSurveyArgs {
	type: 'survey';
	surveyId: string;
	action: 'upsert' | 'delete' | 'archive';
}

export interface SignalRMemberArgs {
	type: 'member';
	memberId: string;
	action: 'upsert' | 'delete';
}

export interface SignalRConnectionArgs {
	type: 'connection';
	connectionId: string;
	action: 'login' | 'logout';
}

const retryPolicy: IRetryPolicy = {
	nextRetryDelayInMilliseconds: (retryContext: RetryContext) => {
		console.warn('Retry delay requested, retryContext:', retryContext);
		return 1000; // retry every second
	},
};

class UnconnectedSignalRConnection implements ISignalRConnection {
	public onReconnected(callback: () => void): void {}
	public onClose(callback: () => void): void {}
	public on(targetName: string, callback: (...args: SignalRArgs[]) => void) {}
	public off(targetName: string) {}
	public invoke(targetName: string, ...args: SignalRArgs[]): Promise<any> {
		return Promise.resolve();
	}
}

class ErroredSignalRConnection implements ISignalRConnection {
	public onReconnected(callback: () => void): void {}
	public onClose(callback: () => void): void {}
	public on(targetName: string, callback: (...args: SignalRArgs[]) => void) {}
	public off(targetName: string) {}

	public invoke(targetName: string, ...args: SignalRArgs[]): Promise<any> {
		return Promise.resolve();
	}
}

class SignalRConnection implements ISignalRConnection {
	constructor(private innerConnection: HubConnection | null) {}
	public onReconnected(callback: () => void): void {
		if (this.innerConnection && this.innerConnection.connectionId) {
			this.innerConnection.onreconnected(callback);
		}
		if (process.env.NODE_ENV !== 'production') {
			if (this.innerConnection && this.innerConnection.connectionId) {
				console.log('Connected to SignalR!', 'onReconnected', callback);
			} else {
				console.log('No Connection to SignalR!', 'onReconnected', callback);
			}
		}
	}
	public onClose(callback: () => void): void {
		if (this.innerConnection && this.innerConnection.connectionId) {
			this.innerConnection.onclose(callback);
		}
		if (process.env.NODE_ENV !== 'production') {
			if (this.innerConnection && this.innerConnection.connectionId) {
				console.log('Connected to SignalR!', 'onClose', callback);
			} else {
				console.log('No Connection to SignalR!', 'onClose', callback);
			}
		}
	}
	public on(targetName: string, callback: (args: SignalRArgs) => void) {
		if (this.innerConnection && this.innerConnection.connectionId) {
			this.innerConnection.on(targetName, callback);
		}
		if (process.env.NODE_ENV !== 'production') {
			if (this.innerConnection && this.innerConnection.connectionId) {
				console.log('Connected to SignalR!', 'on', targetName, callback);
			} else {
				console.log('No Connection to SignalR!', 'on', targetName, callback);
			}
		}
	}

	public off(targetName: string) {
		if (this.innerConnection && this.innerConnection.connectionId) {
			return this.innerConnection.off(targetName);
		}
		if (process.env.NODE_ENV !== 'production') {
			if (this.innerConnection && this.innerConnection.connectionId) {
				console.log('Connected to SignalR!', 'off', targetName);
			} else {
				console.log('No Connection to SignalR!', 'off', targetName);
			}
		}
	}

	public async invoke(targetName: string, args: SignalRArgs) {
		if (this.innerConnection && this.innerConnection.connectionId) {
			return this.innerConnection.invoke(targetName, args);
		}
		if (process.env.NODE_ENV !== 'production') {
			if (this.innerConnection && this.innerConnection.connectionId) {
				console.log('Connected to SignalR!', 'invoke', args);
			} else {
				console.log('No Connection to SignalR!', 'invoke', args);
			}
		}
	}
}

class MyHttpClient extends DefaultHttpClient {
	constructor(logger: ILogger, private userId: string) {
		super(logger);
	}
	public send(request: HttpRequest): Promise<HttpResponse> {
		request.headers = { ...request.headers, 'x-ms-signalr-userid': this.userId };
		if (process.env.NODE_ENV !== 'production') {
			console.log('SignalR!', 'send', this.userId, request);
		}
		return DefaultHttpClient.prototype.send.apply(this, [request]);
	}
}

class MyLogger implements ILogger {
	public log(logLevel: LogLevel, message: string): void {}
}

export const SignalRContext = React.createContext<ISignalRConnection>(new UnconnectedSignalRConnection());

export const SignalR = (props: IProps) => {
	const [connection, setConnection] = useState<HubConnection | null>();
	const [error, setError] = useState(false);

	useEffect(() => {
		if (connection === undefined && props.url) {
			setConnection(null);
			const conBuilder = new HubConnectionBuilder();
			const con = conBuilder
				.withUrl(props.url, {
					httpClient: new MyHttpClient(new MyLogger(), props.userId),
				})
				.withAutomaticReconnect(retryPolicy)
				.build();

			con
				.start()
				.then(() => {
					setConnection(con);
				})
				.catch((ex) => {
					setError(true);
				});
		}
	}, [connection, props.userId, props.url]);

	if (connection && connection.connectionId) {
		return (
			<SignalRContext.Provider value={new SignalRConnection(connection)}>{props.children}</SignalRContext.Provider>
		);
	}
	if (error) {
		return <SignalRContext.Provider value={new ErroredSignalRConnection()}>{props.children}</SignalRContext.Provider>;
	} else {
		return <>{props.children}</>;
	}
};