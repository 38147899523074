import React from 'react';
import { ToggleableIcon } from './ToggleableIcon';
import { useTranslation } from 'react-i18next';

export interface Props {
	question: { allowVotesSplitting: boolean };
}

export const VotesSplittingIcon = (props: Props) => {
	const { t } = useTranslation();

	return <ToggleableIcon 
			icons={[
				{toggled: props.question.allowVotesSplitting, text: t('votesSplitting.allowed'), iconName: 'PieDouble'}
			]}
		/>;
};
