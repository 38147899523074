import styles from './FeedbackButton.module.scss';

import { CommandButton, ContextualMenuItemType, IContextualMenuItem } from 'office-ui-fabric-react';
import React from 'react';
import { VERSION } from '../../shared/config';

export interface Props {
	onClick: () => void;
}

export const FeedbackButton = (props: Props) => {
	let moreOptions: IContextualMenuItem[] = [
		{
			key: 'help',
			text: 'Hilfe',
			onClick: () => {
				window.open('https://votr.itacs.de/hilfe/');
			}
		},
		{
			key: 'feedback',
			text: 'Feedback',
			onClick: () => {
				props.onClick();
			},
		},
		{
			key: 'news',
			text: 'Neuerungen',
			onClick: () => {
				window.open('https://votr.itacs.de/release-notes');
			}
		},
		{ key: 'divider_1', itemType: ContextualMenuItemType.Divider },
		{
			key: 'version',
			text: VERSION,
			disabled: true
		}
	];

	return (
		<CommandButton
			className={styles.feedback}
			menuIconProps={{ iconName: '' }}
			menuProps={{
				items: moreOptions,
			}}
		>	
			<div className={styles.anchor} title="Hilfe, Feedback, Neuerungen">
				<svg
					viewBox='0 0 32 32'
					role='presentation'
					className={[styles.svg, 'icons-faq', 'app-bar-extra-icons-fill-colors'].join(' ')}
				>
					<g>
						<path
							className={[styles['icons-default-fill'], 'icons-unfilled'].join(' ')}
							d='M16,8c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S20.4,8,16,8z M16,23c-3.9,0-7-3.2-7-7s3.2-7,7-7s7,3.2,7,7S19.9,23,16,23zM17.7,11.6c-0.2-0.2-0.5-0.3-0.8-0.4c-0.3-0.1-0.6-0.1-1-0.1c-0.7,0-1.4,0.2-2.1,0.6c-0.2,0.1-0.3,0.4-0.1,0.6
					c0.1,0.2,0.4,0.3,0.6,0.1c0.5-0.3,1.1-0.5,1.6-0.5c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.4,0.2,0.6,0.3c0.1,0.1,0.3,0.3,0.3,0.4
					c0.1,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.2-0.3,0.3-0.5,0.5c-0.2,0.2-0.4,0.3-0.5,0.5
					c-0.2,0.2-0.3,0.4-0.4,0.6c-0.1,0.2-0.2,0.5-0.2,0.8v0.7c0,0.2,0.2,0.4,0.4,0.4s0.4-0.2,0.4-0.4v-0.7c0-0.1,0-0.3,0.1-0.4
					c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.2,0.3-0.3,0.5-0.5c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.2,0.3-0.5,0.4-0.7c0.1-0.3,0.2-0.6,0.2-1
					c0-0.4-0.1-0.7-0.2-1C18.1,12,17.9,11.8,17.7,11.6z'
						></path>
						<circle
							className={[styles['icons-default-fill'], 'icons-unfilled'].join(' ')}
							cx='16'
							cy='20.3'
							r='0.7'
						></circle>
						<path
							className={[styles['icons-default-fill'], styles['icons-filled']].join(' ')}
							d='M16,8c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S20.4,8,16,8z M16,21c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1
					C17,20.6,16.6,21,16,21z M18.5,14.6c-0.1,0.3-0.3,0.5-0.5,0.8c-0.2,0.2-0.4,0.4-0.5,0.6c-0.2,0.1-0.3,0.3-0.4,0.4
					c-0.1,0.1-0.2,0.2-0.2,0.3c0,0,0,0.1,0,0.1v0.6c0,0.4-0.4,0.8-0.8,0.8c-0.4,0-0.8-0.4-0.8-0.8v-0.6c0-0.3,0.1-0.7,0.3-0.9
					c0.1-0.2,0.3-0.4,0.5-0.6c0.2-0.2,0.3-0.3,0.5-0.5c0.1-0.1,0.3-0.3,0.4-0.4c0.1-0.1,0.2-0.3,0.2-0.4c0-0.1,0.1-0.2,0.1-0.4
					c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.4-0.2c-0.2,0-0.4-0.1-0.6-0.1c-0.4,0-0.9,0.1-1.3,0.4
					c-0.4,0.2-0.9,0.1-1.1-0.3c-0.2-0.4-0.1-0.9,0.3-1.1c0.7-0.4,1.4-0.6,2.2-0.6c0.4,0,0.7,0,1,0.1c0.3,0.1,0.6,0.3,0.9,0.5
					c0.3,0.2,0.5,0.5,0.7,0.8c0.2,0.3,0.2,0.7,0.2,1.1C18.7,13.9,18.6,14.3,18.5,14.6z'
						></path>
					</g>
				</svg>
			</div>
		</CommandButton>
	);
};
