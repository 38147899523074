import { messageAdd, messagesClear } from '../store/system/actions';
import { MessageType } from '../store/system/enums';
import { IMessage } from '../store/system/interfaces';
import { store } from '../store';
import i18n from 'i18next';

function t(text: string) {
	if (i18n.exists(text)) {
		return i18n.t(text);
	}
	return text;
}

async function showMessage(text: string, type: MessageType = MessageType.INFO, sticky: boolean = false) {
	store.dispatch(messageAdd(type, t(text), sticky));
	return Promise.resolve();
}

async function showError(error: any, sticky: boolean = false) {
	console.error(error);
	if (error) {
		const message: IMessage = { created: new Date(), type: MessageType.ERROR };
		if (typeof error === 'string') {
			try {
				const err = JSON.parse(error);
				const oError = err['odata.error'];
				if (oError && oError.message && oError.message.value) {
					message.message = oError.message.value;
				}
			} catch (ex) {
				message.message = error;
			}
		} else if (error.response && error.response.data) {
			if (error.response.data instanceof ArrayBuffer && window.TextDecoder) {
				const decoder = new TextDecoder();
				message.message = decoder.decode(error.response.data);
			} else {
				message.message = error.response.data.toString();
			}
		} else if (error.message) {
			message.message = error.message;
		}

		store.dispatch(messageAdd(message.type, t(message.message), sticky));
	}
	return Promise.resolve();
}

async function clearMessages() {
	store.dispatch(messagesClear());
	return Promise.resolve();
}

export { showMessage, showError, clearMessages };
