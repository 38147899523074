import { store } from '../store';
import { IResponse, xhr } from 'src/xhr';
import { IDesign, ISurvey } from 'src/store/business/interfaces';
import { businessLoading, setDesign } from 'src/store/business/actions';
import { BusinessDataType } from 'src/store/business/enums';

export class DesignService {
	public static emptyDesign(): IDesign {
		return {
			logo: '',
			surveyId: '',
		};
	}
	/**
	 *
	 * @param data response.data from Get-Request
	 * @returns Logo parsed from Response
	 */
	public static parse(data: any): IDesign {
		return {
			logo: data.logo,
			surveyId: data.surveyId,
		};
	}
	/**
	 * @param survey cancels Request if no Survey.id
	 * @returns Logo from DB
	 * @event dispatch : redux global state-handling: setDesign
	 */
	public async ensureDesign(survey: ISurvey): Promise<IDesign> {
		const { business } = store.getState();
		if (!survey?.id || business.design?.surveyId === survey?.id) {
			return;
		}
		const response = await xhr(`Survey/${survey.id}/Design`, {
			method: 'GET',
		});

		let design: IDesign = DesignService.parse(response.data);
		store.dispatch(setDesign(design));
		return design;
	}

	/**
	 * sends Request to add chosen Logo to DB
	 * @param design a Logo that implements IDesign
	 * @param survey the current Survey
	 * @event dispatch redux global state-handling: setDesign and businessLoading
	 * @returns Response
	 */
	public async postDesign(design: IDesign, survey: ISurvey): Promise<IResponse> {
		const { business } = store.getState();
		if (business.loading[BusinessDataType.Survey]) {
			return;
		}
		store.dispatch(businessLoading(BusinessDataType.Survey, true));

		try {
			let data = {
				logo: design.logo,
				surveyId: survey.id,
			};
			let response = await xhr(`Survey/${survey.id}/Design`, {
				method: 'POST',
				data: data,
				onError: (error) => {
					return Promise.reject(new Error(error.message));
				},
			});
			store.dispatch(setDesign(DesignService.parse(response.data)));
		} finally {
			store.dispatch(businessLoading(BusinessDataType.Survey, false));
		}
	}
	/**
	 * Deletes current Logo from chosen Survey
	 * @param survey the current Survey
	 * @event dispatch redux global state-handling: setDesign to remove Logo
	 */
	public async deleteDesign(survey: ISurvey): Promise<void> {
		await xhr(`Survey/${survey.id}/Design`, {
			method: 'DELETE',
		});
		store.dispatch(setDesign(null));
	}
}
