import delay from 'lodash/delay';

export const useScrollToAriaInvalid = () => {
	const callBack = () => {
		delay(() => {
			const eEnvalid = document.querySelector('[aria-invalid="true"]');
			if (eEnvalid) {
				eEnvalid.scrollIntoView({
					block: 'end',
					inline: 'nearest',
					behavior: 'smooth',
				});
			}
		}, 250);
	};
	return [callBack];
};
