import React, { ReactNode, useState, useEffect, useRef } from 'react';
import { IQuestion, IAnswerOption, IAttendance } from '../../store/business/interfaces';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styles from './ResultGraph.module.scss';
import { formatFloat } from '../../shared/utils';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import round from 'lodash/round';

interface GraphBarProps {
	allAnswerOptions: IAnswerOption[];
	answerOption: IAnswerOption;
	renderAppendix?: (option: IAnswerOption) => ReactNode;
	useVoteWeightInPercent?: boolean;
	max: number;
}

const GraphBar = (props: GraphBarProps) => {
	const barRef = useRef(null);

	const getOptionResultInPercent = (option: IAnswerOption): number => {
		let optionResultInPercent = 0;
		if (props.useVoteWeightInPercent) {
			optionResultInPercent = option.votesCount;
		} else {
			const totalNumberOfVotes = props.allAnswerOptions.reduce(
				(sum, currentOption) => sum + currentOption.votesCount,
				0
			);
			optionResultInPercent = (option.votesCount * 100) / totalNumberOfVotes;
		}

		optionResultInPercent = isNaN(optionResultInPercent) ? 0 : optionResultInPercent;

		if (!props.useVoteWeightInPercent) {
			optionResultInPercent = round(optionResultInPercent, 1);
		}

		return optionResultInPercent;
	};

	const getAttendeesVoteCountString = (option: IAnswerOption): string => {
		if (!props.useVoteWeightInPercent) return formatFloat(option.votesCount);

		const optionResultInPercent = getOptionResultInPercent(option);
		return `${formatFloat(optionResultInPercent)}`;
	};

	const getAttendeesVoteUnitString = (option: IAnswerOption): string => {
		if (props.useVoteWeightInPercent) return '%';

		return '';
	};

	const getBarStyle = (option: IAnswerOption): React.CSSProperties => {
		return { width: `calc(${(option.votesCount * 100) / props.max}% - ${(option.votesCount * 100) / props.max}px)` };
	};

	const getBarColor = (option: IAnswerOption): React.CSSProperties => {
		return { background: option.votesCount > 0 ? '#6264A7' : '#ddd' };
	};

	const o = props.answerOption;
	return (
		<TransitionGroup enter appear>
			<CSSTransition
				nodeRef={barRef}
				classNames={{
					appear: styles.itemAppear,
					appearActive: styles.itemAppearActive,
					appearDone: styles.itemAppearDone,
				}}
				timeout={100}
			>
				<li ref={barRef} className={styles.item}>
					<h6>{o.title}</h6>
					<div className={styles.flexContainer}>
						<span className={styles.bar} style={{ ...getBarStyle(o), ...getBarColor(o) }}></span>
						<span className={styles.scale}>
							<span className={styles.value}>{getAttendeesVoteCountString(o)}</span>
							{getAttendeesVoteUnitString(o)}
						</span>
						{props.renderAppendix && props.renderAppendix(o)}
					</div>
				</li>
			</CSSTransition>
		</TransitionGroup>
	);
};

export interface Props {
	question: IQuestion;
	renderAppendix?: (option: IAnswerOption) => ReactNode;
}

export const ResultGraph = (props: Props) => {
	const survey = useSelector((state: AppState) => state.business.survey);
	const members = useSelector((state: AppState) => state.business.members);
	const [options, setOptions] = useState<IAnswerOption[]>([]);
	const [notVotedOption, setNotVotedOption] = useState<IAnswerOption>(null);
	const [max, setMax] = useState(0);

	useEffect(() => {
		setOptions([...props.question.answerOptions].sort((a, b) => b.votesCount - a.votesCount));
	}, [props.question]);

	useEffect(() => {
		if (survey.useVoteWeightInPercent) {
			setMax(100);
			setNotVotedOption({
				type: 'virtualNotVoted',
				title: 'Nicht abgestimmt',
				attendees: members.entities
					.filter((m) => !props.question.attendees.some((a) => a.id === m.id))
					.map((m) => {
						return {
							id: m.id,
							voteWeight: m.voteWeight,
							text: m.title,
						} as IAttendance;
					}),
				votesCount: 100 - options.reduce((sum, currentOption) => sum + currentOption.votesCount, 0),
			} as IAnswerOption);
		} else {
			setMax(options[0]?.votesCount || 1);
		}
	}, [options]);

	if (!options || options.length === 0) {
		return <></>;
	}

	return (
		<div className={styles.resultGraph}>
			<ul className={styles.items}>
				{options.map((o, index) => {
					return (
						<GraphBar
							key={index}
							max={max}
							useVoteWeightInPercent={survey.useVoteWeightInPercent}
							allAnswerOptions={options}
							answerOption={o}
							renderAppendix={props.renderAppendix}
						/>
					);
				})}
				{notVotedOption && (
					<GraphBar
						max={max}
						useVoteWeightInPercent={survey.useVoteWeightInPercent}
						allAnswerOptions={options}
						answerOption={notVotedOption}
						renderAppendix={props.renderAppendix}
					/>
				)}
			</ul>
		</div>
	);
};
