import { store } from './store';
import axios, { AxiosRequestConfig, AxiosError } from 'axios';
/**
 * conatins different customized xhr-Requests
 */
let renewToken: () => Promise<string>;
let refreshPage: () => void;

function xhrEvents(onRenewToken: () => Promise<string>, onRefreshPage: () => void) {
	renewToken = onRenewToken;
	refreshPage = onRefreshPage;
}

export interface IResponse {
	data: any;
	contentType: string;
	headers: any;
}

export interface IRequestConfig extends AxiosRequestConfig {
	onError?: (response: AxiosError) => Promise<IResponse>;
}

async function download(url: string) {
	const { system } = store.getState();

	const addFormValue = function(formElement:HTMLFormElement, name:string, value:string){
		const textarea = document.createElement('textarea');
		textarea.name = name;
		textarea.value = value;
		formElement.appendChild(textarea);
	};

	const form = document.createElement('form');
	form.method = 'post';
	form.action = `${system.apiBasePath}/${url}${url.indexOf('?') > -1 ? '&' : '?'}code=${system.apiKey || ''}`;
	addFormValue(form, 'votr-token', system.token || '');
	addFormValue(form, 'votr-group-id', system.groupId || '');
	addFormValue(form, 'votr-tenant-id', system.context?.tid || '');
	addFormValue(form, 'votr-user-hint', system.context?.userPrincipalName || '');

	document.body.appendChild(form);
	form.submit();
	document.body.removeChild(form);
}
/**
 * templates for GET/POST/DELETE-Requests with default GET
 * @param url API-Endpoint of Azure Function
 * @param opts special RequestConfigurations
 * @returns Promise IResponse Data
 * uses global state to get valid apiKey, token and Id's necessary to trigger Azure Fucntion and access DB
 * uses Axios
 */
async function xhr(url: string, opts?: IRequestConfig): Promise<IResponse> {
	const { system } = store.getState();
	opts = { method: 'GET', ...opts, withCredentials: true };
	opts.headers = {
		'content-type': 'application/json',
		'x-functions-key': system.apiKey || '',
		'x-votr-token': system.token || '',
		'x-votr-group-id': system.groupId || '',
		'x-votr-tenant-id': system.context?.tid || '',
		'x-votr-user-hint': system.context?.userPrincipalName || '',
		...opts.headers,
	} as Record<string, string>;
	//disable cache for ie
	if ((window.document as any).documentMode && opts.method!.toLocaleLowerCase() === 'get') {
		opts.params = { _r: Math.random().toString() };
	}
	try {
		const response = await axios(system.apiBasePath !== undefined ? `${system.apiBasePath}/${url}` : `/${url}`, opts);
		const contentType = (response.headers['content-type'] || '').toLowerCase();
		return { data: response.data, contentType, headers: response.headers };
	} catch (e) {
		const ex = e as AxiosError;
		if (ex.response && ex.response.status === 401 && !!renewToken) {
			const oldToken = system.token;
			const newToken = await renewToken();
			if (oldToken !== newToken) {
				return xhr(url, opts);
			} else {
				refreshPage?.();
			}
		}
		if (opts.onError) {
			return opts.onError(ex);
		} else {
			// showError(e);
			return Promise.reject(e);
		}
	}
}

export { download, xhr, xhrEvents };
