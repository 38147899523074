import React from 'react';
import styles from './RTEField.module.scss';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import VotrEditor from 'ckeditor5-votr-build';

export interface Props {
	text: string;
	placeholder: string;
	onBlur(text: string): void;
	className?: string;
	disabled: boolean;
	maxLength?: number;
}

export const RTEField = (props: Props) => {
	const togglePlaceholder = (editor: any) => {
		const noPlaceHolderClass = 'ck-noplaceholder';
		const data = editor.getData();
		const inputP = ((editor.sourceElement as HTMLDivElement).nextSibling as HTMLDivElement).querySelector('.ck-content p') as HTMLParagraphElement;
		if(inputP != null){
			if(!data) {
				inputP.classList.remove(noPlaceHolderClass);
			}
			else {
				inputP.classList.add(noPlaceHolderClass);
			}
		}
	};

    return (
        <div className={styles.descriptionContainer}>
            <CKEditor
				config={{placeholder:props.placeholder}}
				editor={VotrEditor}
                data={props.text}
				onBlur={(event, editor) => {
					const data = editor.getData();
					props.onBlur(`${data}`);
				}}
				onChange={(event, editor) => {
					const data = `${editor.getData()}`;
					if(props.maxLength && data.length > props.maxLength) {
						// TODO: find better way to prevent event bubbling here (to prevent update of value to stay within {maxLength} characters); I couldn't find one from the documentation of CKEditor.
						throw(new Error('Beschreibung ist zu lang!'));
					}
					else {
						togglePlaceholder(editor);
					}
				}}
				onFocus={(event, editor) => {togglePlaceholder(editor);}}
				onReady={togglePlaceholder}
				disabled={props.disabled}
			>
			</CKEditor>
        </div>
    );
};