import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CommandButton, ChoiceGroup, IRenderFunction, IChoiceGroupOption } from 'office-ui-fabric-react';
import { RTEField } from '../RTEField';
import { AutomaticMultilineTextField } from '../../atoms/AutomaticMultilineTextField';
import { IQuestion, QuestionType } from '../../../store/business/interfaces';
import styles from '../Sidepanel.module.scss'; // TODO: use own module.scss
import { IconWithCallout } from '../../atoms/IconWithCallout';

function getText(kind: QuestionType): string | undefined {
	switch (kind) {
		case 'choice':
			return `Mit diesem Abstimmungstyp ermöglichen Sie den Teilnehmern aus einer Liste von Antwortmöglichkeiten zu wählen. Über die zusätzlichen Einstellungen können Sie Ihre Abstimmung an Ihren Anwendungsfall anpassen.`;
		case 'text':
			return `Teilnehmer können eine freie Antwort eingeben. Dieser Abstimmungstyp eignet sich besonders gut, um ein Stimmungsbild von den Teilnehmern abzufragen.`;
		case 'person':
			return `Teilnehmer können in einer Liste von Personen jeweils mit Ja, Nein oder Enthaltung stimmen. Dieser Typ eignet sich u. a. für Gremienwahlen, Block- bzw. Listenwahlen.`;
	}
}

interface IQuestionChoiceGroupOption extends IChoiceGroupOption {
	kind: QuestionType;
}

const renderChoiceField: IRenderFunction<IQuestionChoiceGroupOption> = (p, defaultRenderer) => {
	const field = defaultRenderer(p);
	const icon = (
		<IconWithCallout
			iconName='Info'
			iconContainerClassName={styles.infoIcon}
			renderCalloutContent={() => {
				return <span>{getText(p.kind)}</span>;
			}}
		/>
	);
	return (
		<>
			{icon}
			{field}
		</>
	);
};

export interface Props {
	question: IQuestion;
	disabled: boolean;
	showError: boolean | undefined;
	setQuestion: Dispatch<SetStateAction<IQuestion>>;
	onCancel: () => void;
}

export const QuestionHead = (props: Props) => {
	const [titleIsValid, setTitleIsValid] = useState(true);
	const [showValidationMessages, setShowValidationMessages] = useState(props.showError);

	useEffect(() => {
		setTitleIsValid(props.question.title > '');
	}, [props.question.title]);

	useEffect(() => {
		setShowValidationMessages(props.showError);
	}, [props.showError]);

	return (
		<>
			<header>
				<div className={[styles.flexContainer, styles.spaceBetween].join(' ')}>
					<div className={styles.panelHeader}>
						<h1>Abstimmung {props.question.status === 'unsaved' ? 'anlegen' : 'bearbeiten'}</h1>
					</div>
					<div className={styles.panelButtons}>
						<CommandButton
							onClick={() => {
								props.onCancel();
							}}
							iconProps={{ iconName: 'ChromeClose' }}
							className={styles.closeIcon}
						/>
					</div>
				</div>
			</header>
			<div className={styles.surveyTitleInput}>
				<AutomaticMultilineTextField
					title='Frage der Abstimmung'
					defaultValue={props.question.title}
					onChange={(evt, val) => {
						props.setQuestion({ ...props.question, title: val });
					}}
					onBlur={() => setShowValidationMessages(true)}
					disabled={props.disabled}
					placeholder='Frage der Abstimmung eingeben'
					onFocus={(evt) => {
						evt.currentTarget.select();
					}}
					errorMessage={!titleIsValid && showValidationMessages ? 'Bitte geben Sie eine Frage ein.' : ''}
					maxLength={300}
				/>
			</div>
			<RTEField
				text={props.question.description}
				placeholder={'Beschreibung der Abstimmung eingeben (optional)'}
				onBlur={(val) => props.setQuestion({ ...props.question, description: val })}
				disabled={props.disabled}
				maxLength={30000} /* max size of a cell in table storage entity is 32.768, leave some space there */
			/>

			<ChoiceGroup
				label={'Abstimmungstyp'}
				defaultSelectedKey={props.question.kind}
				options={
					[
						{
							key: 'choice',
							text: 'Auswahl',
							iconProps: { iconName: 'CheckList' },
							onRenderField: renderChoiceField,
							kind: 'choice',
						},
						{
							key: 'person',
							text: 'Personenwahl',
							iconProps: { iconName: 'UserFollowed' },
							onRenderField: renderChoiceField,
							kind: 'person',
						},
						{
							key: 'text',
							text: 'Texteingabe',
							iconProps: { iconName: 'InsertTextBox' },
							onRenderField: renderChoiceField,
							kind: 'text',
						},
					] as IQuestionChoiceGroupOption[]
				}
				onChange={(ev, option) => {
					props.setQuestion({ ...props.question, kind: option.key as QuestionType });
				}}
				className={styles.questionKindSelector}
			/>
		</>
	);
};
