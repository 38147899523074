import React from 'react';
import { Dispatch, SetStateAction, useState } from 'react';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react';

import { SecurityToggle } from '../../molecules/QuestionForm/SecurityToggle';
import { QuestionHead } from '../../molecules/QuestionForm/QuestionHead';

import { IQuestion } from '../../../store/business/interfaces';
import { QuestionService } from '../../../services/QuestionService';

import styles from '../../molecules/Sidepanel.module.scss'; // TODO: use own module.scss

interface Props {
	disabled: boolean;
	question: IQuestion;
	setQuestion: Dispatch<SetStateAction<IQuestion>>;
	onSave: (question: IQuestion) => void;
	onCancel: () => void;
}

export const TextQuestionForm = (props: Props) => {
	const [valid, setValid] = useState<boolean | undefined>(undefined);

	const onClickSave = () => {
		if (props.question.title) {
			const question = {
				...QuestionService.emptyQuestion(),
				...props.question,
				attendees: [],
				loaded: undefined,
				votesPerMember: 1,
				allowVotesSplitting: false,
				answerOptions: [],
			};
			props.onSave(question);
		} else setValid(false);
	};
	return (
		<div className={styles.formContainer}>
			<QuestionHead
				question={props.question}
				disabled={props.disabled}
				showError={valid === undefined ? undefined : !valid}
				setQuestion={props.setQuestion}
				onCancel={props.onCancel}
			/>
			<SecurityToggle
				checked={props.question.security === 'private'}
				disabled={props.disabled}
				onChanged={(checked) => props.setQuestion({ ...props.question, security: checked ? 'private' : 'public' })}
			/>
			<div className={styles.buttonContainer}>
				<DefaultButton text='Abbrechen' onClick={() => props.onCancel()} />
				<PrimaryButton text='Speichern' onClick={onClickSave} disabled={props.disabled || !props.question.title} />
			</div>
		</div>
	);
};
